import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../components/layouts/DashboardLayout';
import LogoOnlyLayout from '../components/layouts/LogoOnlyLayout';
import Loading from '../components/Loading';
import ACFAODefinitions from '../pages/ACFAODefinitions';
import FlightFactor from '../pages/FlightFactor';
import Formula from '../pages/Formula';
import AuthGuard from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password', element: <ChangePassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <Dashboard />, index: true }],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
      children: [],
    },
    {
      path: '/aktivasyon/:authCode/:ldap/:languageCode',
      element: <UserActivation />,
      children: [],
    },
    {
      path: 'CustomerAgreement',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <CustomerAgreement />, index: true }],
    },
    {
      path: 'CAMakerChecker',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <CAMakerChecker />, index: true },
        { path: ':id', element: <CAMakerChecker /> },
      ],
    },
    {
      path: 'StandardAgreement',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <StandardAgreement />, index: true }],
    },
    {
      path: 'RoyaltyAgreement',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <RoyaltyAgreement />, index: true }],
    },
    {
      path: 'AutoRenewal',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <AutoRenewal />, index: true }],
    },
    {
      path: 'DefineUser',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <DefineUser />, index: true },
        { path: ':id', element: <CreateUpdateUser /> },
      ],
    },
    {
      path: 'DefineRoleGroup', // TODO change to define role
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <DefineRole />, index: true },
        { path: ':id', element: <CreateUpdateRole /> },
      ],
    },
    {
      path: 'Airlines',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/Airlines', element: <TestPage /> },
        { path: ':id', element: <CreateUpdateTest /> },
      ],
    },

    {
      path: 'Registrations',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <Registration />, index: true },
        { path: ':id', element: <CreateUpdateRegistration /> },
      ],
    },

    {
      path: 'DefineMakerChecker',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <Definitions />, index: true }],
    },
    {
      path: 'requestList',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <MakerChecker />, index: true }],
    },
    {
      path: 'DefineParentMenu',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <CreateParentMenu />, index: true }],
    },
    {
      path: 'DynamicMenu',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <CreateDynamicMenu />, index: true },
        { path: ':id', element: <CreateUpdateMenu /> },
      ],
    },
    {
      path: 'Countries',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <CountriesPage />, index: true },
        { path: ':id', element: <CreateUpdateCountries /> },
      ],
    },
    {
      path: 'Airports',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <Airports />, index: true },
        { path: ':id', element: <CreateUpdateAirports /> },
      ],
    },

    {
      path: 'UnitGroups',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <UnitGroups />, index: true },
        { path: ':id', element: <CreateUpdateUnitGroups /> },
      ],
    },

    {
      path: 'Agents',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <Agent />, index: true },
        { path: ':id', element: <CreateUpdateOperationAgents /> },
      ],
    },

    {
      path: 'Agents',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <Agent />, index: true },
        { path: ':id', element: <CreateUpdateOperationAgents /> },
      ],
    },

    {
      path: 'PortfolioManagers',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <PortfolioManagers />, index: true },
        { path: ':id', element: <CreateUpdatePortfolioManagers /> },
      ],
    },

    {
      path: 'AirlineGroups',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <AirlineGroups />, index: true },
        { path: ':id', element: <CreateUpdateAirlineGroups /> },
      ],
    },

    {
      path: 'FinancialSetting',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <FinancialSetting />, index: true },
        { path: ':id', element: <CreateUpdateFinancialSetting /> },
      ],
    },

    {
      path: 'Currencies',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/Currencies', element: <CurrenciesPage /> },
        { path: ':id', element: <CreateUpdateCurrencies /> },
      ],
    },

    {
      path: 'FinancialInstitutions',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/FinancialInstitutions',
          element: <FinancialInstitutionsPage />,
        },
        { path: ':id', element: <CreateUpdateFinancialInstitutions /> },
      ],
    },

    {
      path: 'ClosingPeriod',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/ClosingPeriod', element: <ClosingPeriod /> },
        { path: ':id', element: <CreateUpdateClosingPeriod /> },
      ],
    },
    {
      path: 'FormulaTable',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <Formula />, index: true }],
    },
    {
      path: 'ACFAODefinitions',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <ACFAODefinitions />, index: true }],
    },
    {
      path: 'CeilingRatesTable',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <CeilingRates />, index: true }],
    },
    {
      path: 'FlightFactorTable',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <FlightFactor />, index: true }],
    },
    {
      path: 'InvoiceCreate',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <InvoiceCreate />, index: true }],
    },
    {
      path: 'GatInvoicing',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <GatInvoicing />, index: true }],
    },
    {
      path: 'Invoices',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <Invoices />, index: true }],
    },
    {
      path: 'SlaKpi',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <QualityModulePage />, index: true },
        { path: ':id', element: <CreateUpdateQualityModule /> },
      ],
    },
    {
      path: 'AirlineWeightDefinition',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <QMWeightDefinitions />, index: true },
        { path: ':id', element: <CreateUpdateQualityModule /> },
      ],
    },
    {
      path: 'Reminder',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <QMReminder />, index: true },
        { path: ':id', element: <CreateUpdateQualityModule /> },
      ],
    },
    {
      path: 'SlaMeasurement',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '*', element: <QMActual />, index: true },
        { path: ':id', element: <CreateUpdateQualityModule /> },
      ],
    },
    {
      path: 'ServiceForm',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <ServiceFormCreate />, index: true }],
    },
    {
      path: 'huboperations',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '*', element: <HubOperations />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const Login = Loadable(lazy(() => import('../pages/Auth/Login')));
const ForgotPassword = Loadable(
  lazy(() => import('../pages/Auth/ForgotPassword'))
);
const ChangePassword = Loadable(
  lazy(() => import('../pages/Auth/ChangePassword'))
);

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const UserProfile = Loadable(
  lazy(() => import('../pages/dashboard/UserProfile'))
);

const DefineUser = Loadable(lazy(() => import('../pages/DefineUser')));
const CreateUpdateUser = Loadable(
  lazy(() => import('../pages/DefineUser/CreateUpdateUser'))
);

const DefineRole = Loadable(lazy(() => import('../pages/DefineRole')));

const Definitions = Loadable(
  lazy(() => import('../pages/MakerChecker/Definitions'))
);
const MakerChecker = Loadable(
  lazy(() => import('../pages/MakerChecker/RequestList'))
);

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const CustomerAgreement = Loadable(
  lazy(() => import('../pages/Agreement/Customer'))
);
const CAMakerChecker = Loadable(
  lazy(() => import('../pages/Agreement/CAMakerChecker'))
);
const StandardAgreement = Loadable(
  lazy(() => import('../pages/Agreement/Standard'))
);
const RoyaltyAgreement = Loadable(
  lazy(() => import('../pages/Agreement/Royalty'))
);
const AutoRenewal = Loadable(
  lazy(() => import('../pages/Agreement/AutoRenewal'))
);

const CreateParentMenu = Loadable(
  lazy(() => import('../pages/DynamicMenu/components/CreateParentMenu'))
);
const CreateDynamicMenu = Loadable(
  lazy(() => import('../pages/DynamicMenu/components/CreateDynamicMenu'))
);
const CreateUpdateMenu = Loadable(
  lazy(() =>
    import('../pages/DynamicMenu/components/CreateDynamicMenu/CreateUpdateMenu')
  )
);
const CreateUpdateRole = Loadable(
  lazy(() => import('../pages/DefineRole/CreateUpdateRole'))
);
const CreateUpdateTest = Loadable(
  lazy(() => import('../pages/TestPage/CreateUpdateTest'))
);
const CreateUpdateCountries = Loadable(
  lazy(() => import('../pages/CountriesPage/CreateUpdateCountries'))
);
const CreateUpdateRegistration = Loadable(
  lazy(() => import('../pages/Registration/CreateUpdateRegistration'))
);
const CreateUpdateCurrencies = Loadable(
  lazy(() => import('../pages/CurrenciesPage/CreateUpdateCurrencies'))
);
const CreateUpdateFinancialInstitutions = Loadable(
  lazy(() =>
    import(
      '../pages/FinancialInstitutionsPage/CreateUpdateFinancialInstitutions'
    )
  )
);
const CreateUpdateAirports = Loadable(
  lazy(() => import('../pages/Airports/CreateUpdateAirports'))
);
const CreateUpdatePortfolioManagers = Loadable(
  lazy(() => import('../pages/PortfolioManagers/CreateUpdatePortfolioManagers'))
);
const CreateUpdateAirlineGroups = Loadable(
  lazy(() => import('../pages/AirlineGroups/CreateUpdateAirlineGroups'))
);
const CreateUpdateOperationAgents = Loadable(
  lazy(() => import('../pages/Agent/CreateUpdateOperationAgents'))
);
const CreateUpdateUnitGroups = Loadable(
  lazy(() => import('../pages/UnitGroups/CreateUpdateUnitGroups'))
);
const CreateUpdateFinancialSetting = Loadable(
  lazy(() => import('../pages/FinancialSetting/CreateUpdateFinancialSetting'))
);
const CreateUpdateClosingPeriod = Loadable(
  lazy(() => import('../pages/ClosingPeriod/CreateUpdateClosingPeriod'))
);
const CreateUpdateQualityModule = Loadable(
  lazy(() => import('../pages/QualityModule/CreateUpdateQualityModule'))
);

const UserActivation = Loadable(lazy(() => import('../pages/UserActivation')));
const TestPage = Loadable(lazy(() => import('../pages/TestPage')));
const CountriesPage = Loadable(lazy(() => import('../pages/CountriesPage')));
const CurrenciesPage = Loadable(lazy(() => import('../pages/CurrenciesPage')));
const FinancialInstitutionsPage = Loadable(
  lazy(() => import('../pages/FinancialInstitutionsPage'))
);
const Registration = Loadable(lazy(() => import('../pages/Registration')));
const Airports = Loadable(lazy(() => import('../pages/Airports')));
const PortfolioManagers = Loadable(
  lazy(() => import('../pages/PortfolioManagers'))
);
const AirlineGroups = Loadable(lazy(() => import('../pages/AirlineGroups')));
const Agent = Loadable(lazy(() => import('../pages/Agent')));
const UnitGroups = Loadable(lazy(() => import('../pages/UnitGroups')));
const FinancialSetting = Loadable(
  lazy(() => import('../pages/FinancialSetting'))
);
const ClosingPeriod = Loadable(lazy(() => import('../pages/ClosingPeriod')));
//const ConcessionFee = Loadable(lazy(()=> import('../pages/ConcessionFee')));
const CeilingRates = Loadable(lazy(() => import('../pages/CeilingRates')));

const InvoiceCreate = Loadable(lazy(() => import('../pages/Invoice/Create')));
const HubOperations = Loadable(lazy(() => import('../pages/Reports/HubOperations')));
const GatInvoicing = Loadable(
  lazy(() => import('../pages/Invoice/GatInvoicing'))
);
const Invoices = Loadable(lazy(() => import('../pages/Invoice/Invoices')));
const QualityModulePage = Loadable(
  lazy(() => import('../pages/QualityModulePage'))
);
const QMWeightDefinitions = Loadable(
  lazy(() => import('../pages/QualityModulePage/QMWeightDefinitions'))
);
const QMActual = Loadable(
  lazy(() => import('../pages/QualityModulePage/QMActual/QMActual'))
);
const QMReminder = Loadable(
  lazy(() => import('../pages/QualityModulePage/QMReminder/QMReminder'))
);

const ServiceFormCreate = Loadable(
  lazy(() => import('../pages/ServiceForm/Create'))
);
