import request from './request';
import * as paths from './paths';

class CoreAPI {
  static acModels = async () => {
    return await request.get(paths.acModel);
  };

  static airlines = async () => {
    return await request.get(paths.airline);
  };

  static airlineSave = async (params) => {
    return await request.post(paths.airlineSave, params);
  };

  static airlinesByCode = async (code) => {
    return await request.get(paths.airlineByCode + code);
  };

  static airlineGroups = async () => {
    return await request.get(paths.airlineGroup);
  };

  static airports = async () => {
    return await request.get(paths.airportnamecode);
  };
  static airportsByIATACode = async (code) => {
    return await request.get(paths.airportByIATACode + code);
  };

  static operatedAirports = async (branchCode) => {
    return await request.get(paths.operatedAirport + branchCode);
  };

  static coefficients = async () => {
    return await request.get(paths.coefficient);
  };

  static companies = async () => {
    return await request.get(paths.company);
  };

  static countries = async () => {
    return await request.get(paths.country);
  };

  static currencies = async () => {
    return await request.get(paths.currency);
  };

  static customerListByName = async (name) => {
    return await request.post(paths.customerByName, { code: name });
  };

  static representativeListByName = async (name) => {
    return await request.post(paths.representativeByName, { name: name });
  };

  static customerListWithPaging = async (pageNumber) => {
    return await request.get(paths.customerWithPaging + pageNumber);
  };

  static customerSave = async (params) => {
    return await request.post(paths.customerSave, params);
  };

  static financialInstitution = async () => {
    return await request.get(paths.financialInstitution);
  };

  static packageDefinitions = async () => {
    return await request.get(paths.packageDefinition);
  };

  static paymentTypes = async () => {
    return await request.get(paths.paymentType);
  };

  static portfolioManagers = async () => {
    return await request.get(paths.portfolioManager);
  };

  static portfolioManagersInuse = async () => {
    return await request.get(paths.portfolioManagerInuse);
  };

  static registrations = async () => {
    return await request.get(paths.registration);
  };

  static registrationsByCustomerCode = async (code) => {
    return await request.get(paths.registrationsCustomerCode + code);
  };

  static registrationsByCode = async (code) => {
    return await request.get(paths.registrationByCode + code);
  };

  static registrationSave = async (params) => {
    return await request.post(paths.registrationSave, params);
  };

  static financialSettings = async (params) => {
    return await request.post(paths.financialSettings, params);
  };

  static representatives = async () => {
    return await request.get(paths.representative);
  };

  static services = async () => {
    return await request.get(paths.service);
  };

  static servicesByCode = async (code) => {
    return await request.get(paths.serviceByCode + code);
  };

  static servicesRoyaltyList = async () => {
    return await request.get(paths.serviceRoyalty);
  };

  static serviceGroups = async () => {
    return await request.get(paths.serviceGroup);
  };

  static units = async () => {
    return await request.get(paths.unit);
  };
  static unitGroups = async () => {
    return await request.get(paths.unitGroup);
  };
  static airportsPageble = async () => {
    return await request.get(paths.airportsPageble);
  };
  static agents = async () => {
    return await request.get(paths.agent);
  };

  static FinancialSettings = async () => {
    return await request.get(paths.FinancialSettings);
  };
  static ClosingPeriod = async () => {
    return await request.get(paths.ClosingPeriod);
  };
  static ClosingPeriodSave = async (params) => {
    return await request.post(paths.ClosingPeriodSave, params);
  };
  static HubOperations = async () => {
    return await request.get(paths.HubOperations);
  };
  static LoadType = async () => {
    return await request.get(paths.LoadType);
  }
}

export { CoreAPI };
