// Core API paths

export const acModel = 'api/model';
export const airline = 'api/airline/inUseTrue';
export const airlineSave = 'api/airline/save';
export const airlineByCode = 'api/airline/';
export const airlineGroup = 'api/airlineGroup';
export const airport = 'api/airport';
export const airportnamecode = '/api/airports/name/code/';
export const airportByIATACode = 'api/airports/';
export const operatedAirport = 'api/operatedairports/';
export const coefficient = 'api/coefficient';
export const company = 'api/company';
export const country = 'api/country';
export const currency = 'api/currency';
export const customer = 'api/customers';
export const customerSave = 'apicustomer/save';
export const financialSettings = 'financialSettings/save';
export const customerByName = 'api/customers/search/';
export const customerWithPaging = 'api/customers/pageable?size=50&page=';
export const financialInstitution = 'api/financialInstutation';
export const packageDefinition = 'api/packageDefinition';
export const paymentType = 'api/paymentType';
export const portfolioManager = '/api/portfolioManager';
export const portfolioManagerInuse = '/api/portfolioManager/inuse';
export const registration = 'api/registration/getAll';
export const registrationsCustomerCode = 'api/registrations/customerCode/';
export const registrationSave = 'api/registration/save';
export const registrationByCode = 'api/registration/search/';
export const representative = 'api/representative';
export const representativeByName = 'api/representatives/search/';
export const service = 'api/services';
export const serviceByCode = 'api/service/';
export const serviceRoyalty = 'api/serviceRoyalties';
export const serviceGroup = 'api/serviceGroups';
export const unit = 'api/unit';
export const unitGroup = 'api/unitGroup';
export const airportsPageble = 'api/airports/{pageable}?page=36&size=96';
export const agent = 'api/agent';
export const FinancialSettings = 'api/financialSettings';
export const ClosingPeriod = 'api/closingPeriod';
export const HubOperations = 'api/hubOperation';
export const ClosingPeriodSave = 'api/closingPeriod/save';
export const LoadType = 'api/loadType';