/**
 * Store take long to be ready
 * https://stackoverflow.com/questions/65217815/redux-handling-really-large-state-object
 */
import { configureStore } from '@reduxjs/toolkit';
import ARnavigationReducer from './agreement/autorenewal/ARnavigationSlice';
import AutoRenewalReducer from './agreement/autorenewal/selectedARSlice';
import agreementCommonReducer from './agreement/common/agreementCommonSlice';
import clipboardSliceReducer from './agreement/common/clipboardSlice';
import conditionSliceReducer from './agreement/conditionSlice';
import CAnavigationReducer from './agreement/customer/navigationSlice';
import CAselectedAgreementReducer from './agreement/customer/selectedAgreementSlice';
import RAnavigationReducer from './agreement/royalty/navigationSlice';
import RAselectedAgreementReducer from './agreement/royalty/selectedRASlice';
import SAnavigationReducer from './agreement/standard/navigationSlice';
import SAselectedAgreementReducer from './agreement/standard/selectedSASlice';
import clearSliceReducer from './clearSlice';
import ConcessionFeeReducer from './concessionfee/concessionFeeSlice';
import InvoiceGatFilterNavigationReducer from './invoice/invoiceGatFilterSlice';
import InvoiceNavigationReducer from './invoice/invoiceSlice';
import InvoicesNavigationReducer from './invoice/invoicesSlice';
import MainUIReducer from './mainSlice';
import menuSliceReducer from './menu/menuSlice';
import QMnavigationReducer from './qualityModule/navigationSlice';
import SelectedQualityKPISlice from './qualityModule/selectedQualityKPISlice';
import QMWeightDefinitionsReducer from './qualityModule/weightDefinitionsSlice';
import ServiceFormNavigationReducer from './serviceForm/serviceFormSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }),
  reducer: {
    agreementCommon: agreementCommonReducer,
    CAselectedAgreement: CAselectedAgreementReducer,
    CAnavigation: CAnavigationReducer,
    SAselectedAgreement: SAselectedAgreementReducer,
    SAnavigation: SAnavigationReducer,
    condition: conditionSliceReducer,
    RAselectedAgreement: RAselectedAgreementReducer,
    RAnavigation: RAnavigationReducer,
    ARnavigation: ARnavigationReducer,
    AutoRenewal: AutoRenewalReducer,
    InvoiceNavigation: InvoiceNavigationReducer,
    ServiceFormNavigation: ServiceFormNavigationReducer,
    InvoiceGatFilterNavigation: InvoiceGatFilterNavigationReducer,
    InvoicesNavigation: InvoicesNavigationReducer,
    menu: menuSliceReducer,
    clipboard: clipboardSliceReducer,
    clear: clearSliceReducer,
    MainUI: MainUIReducer,
    ConcessionFee: ConcessionFeeReducer,
    selectedQualityKPI: SelectedQualityKPISlice,
    QMnavigation: QMnavigationReducer,
    QMWeightDefinitions: QMWeightDefinitionsReducer,
  },
});
