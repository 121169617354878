import request from './request';
import requestInvoice from './requestInvoice';

class InvoiceAPI {
    static representative = async () => {
        return await request.get("api/representative");
    }
    static searchRepresentatives = async (params) => {
        return await request.get("api/representatives/" + params);
    }
    static formType = async () => {
        return await request.get("api/formType");
    }
    static flightType = async () => {
        return await request.get("api/flightType");
    }
    static currency = async () => {
        return await request.get("api/currency");
    }
    static scheduleType = async () => {
        return await request.get("api/scheduleType");
    }
    static serviceGroups = async () => {
        return await request.get("api/serviceGroups");
    }
    static serviceCustomerGroups = async (params) => {
        return await request.post("api/serviceCustomerGroupingsByCompany", params);
    }
    static operatedAirPorts = async (branchCode) => {
        return await request.get("api/operatedairports/" + branchCode);
    }
    static airline = async (page = 0) => {
        return await request.get("api/airlines/pageable?page=" + page);
    }
    static airlineSearch = async (params) => {
        return await request.post("api/airlines/codeAndName/code" + params.code, params);
    }
    static registration = async (page = 0) => {
        return await request.get("api/registration/pageble?size=10&page=" + page);
    }
    static registrationSearch = async (params) => {
        return await request.post("api/registration/search/code" + params.code, params);
    }
    static searchAirports = async (search) => {
        return await request.post("api/airports/search/codeNameRequest", search);
    }
    static searchOperatedAirportsWithCodeOrName = async (search) => {
        return await request.post("api/operatedairports/search/codeNameRequest", search);
    }
    static searchAirportsWithCompany = async (params) => {
        return await request.post("api/operatedairports/", params);
    }
    static customers = async (page = 0) => {
        return await request.get("api/customers/pageable?size=50&page=" + page);
    }
    static searchCustomers = async (params) => {
        return await request.post("api/customers/search/", params);
    }
    static calculateAndGroupForms = async (params) => {
        // Dummy servis sonrasında kaldırılmalı.
        // if (process.env.REACT_APP_ENV == "development") {
        // return await requestInvoice.get("/api/invoice/calculateDummy");
        // } else {
        return await requestInvoice.post("/api/invoice/calculateAndGroupForms/calculate", params);
        // }
    }
    static groupingCalculatedServices = async (params) => {
        // Dummy servis sonrasında kaldırılmalı.
        // if (process.env.REACT_APP_ENV == "development") {
        // return await requestInvoice.get("/api/invoice/calculateDummy");
        // } else {
        return await requestInvoice.post("/api/invoice/groupingCalculatedServices/calculate", params);
        // }
    }
    static findFilteredServiceForms = async (params) => {
        return await requestInvoice.post("/api/invoice/findFilteredServiceForms/calculate", params);
    }
    static createInvoice = async (params) => {
        return await requestInvoice.post("/api/invoice/save", params);
    }
    static invoiceSearch = async (params) => {
        let page = ""
        if (params.page == 1) {
            page = 0
        } else {
            page = Number(params.page) - 1
        }
        return await requestInvoice.post(`/api/invoice/search?page=${page}`, params);
    }
    static favoriteSave = async (params) => {
        return await requestInvoice.post("/api/invoice/favoriteSave", params);
    }
    static favorites = async (params) => {
        return await requestInvoice.get(`/api/invoice/${params.userId}/${params.type}/?size=5${'&page=' + params.page}`);
    }
    static deleteFavorite = async (params) => {
        return await requestInvoice.delete("/api/invoice/" + params);
    }
    static finalizeInvoice = async (params) => {
        return await requestInvoice.post("/api/invoice/finalize", params);
    }
    static cancelInvoice = async (params) => {
        return await requestInvoice.post("/api/invoice/cancel", params);
    }
    static viewInvoiceById = async (params) => {
        return await requestInvoice.getblob(`/api/viewInvoiceById/${params.invoiceId}`);
    }
    static viewInvoiceDetailsById = async (params) => {
        return await requestInvoice.getblob(`/api/viewInvoiceDetailById/${params.invoiceId}/false`);
    }
    static viewInvoiceDetailsByIdXls = async (params) => {
        return await requestInvoice.getblob(`/api/viewInvoiceDetailById/${params.invoiceId}/true`);
    }
    static viewPreInvoiceDetailById = async (params) => {
        return await requestInvoice.postblob('/api/preInvoiceDetailById/view', params);
    }
    static updateVat = async (params) => {
        return await requestInvoice.post("/api/updateVat", params);
    }
    static invoicePrefixList = async () => {
        return await requestInvoice.get("/api/invoicePrefixList");
    }
    static loadType = async () => {
        return await request.get("api/loadType");
    }
    static openScfPdf = async (scfId) => {
        return await requestInvoice.getblob(`api/openScfPdf/${scfId}`);
    }

    static openScfAttachment = async (scfAttachmentId) => {
        return await requestInvoice.getblob(`api/openScfAttachment/${scfAttachmentId}`);
    }
}

export { InvoiceAPI };
