import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocale from '../../../hooks/useLocale';
import useSettings from '../../../hooks/useSettings';

import { TableCell, TableRow } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import toast from 'react-hot-toast';
import secureLocalStorage from 'react-secure-storage';
import IconButton from '../../../components/IconButton';
import Iconify from '../../../components/Iconify';
import {
  setACFAODefinitionsToggleDuplicateKey,
  setACFAODefinitionsToggleEditKey,
} from '../../../contexts/concessionfee/concessionFeeSlice';
import { setGlobalLoading } from '../../../contexts/mainSlice';
import { ConcessionFeeAPI, InvoiceAPI } from '../../../services';
import PriceFunctionBox from '../PriceFunctionBox';

export default function ACFAODefinitionForm(props) {
  const { t } = useLocale();
  const { themeSize } = useSettings();
  //const themeSize = "small";
  const translate = (value) => {
    let _t = `ConcessionFee.FormulaTable.index.${value}`;
    return t(_t);
  };
  const action = ['Add', 'Edit', 'Duplicate', 'Search'];
  const form = useSelector(
    (state) => state.ConcessionFee.ACFAODefinitions.Form
  );
  const data = useSelector(
    (state) => state.ConcessionFee.ACFAODefinitions.Data
  );
  const constants = useSelector((state) => state.ConcessionFee);
  const [airPortData, setAirPortData] = useState([]);
  const [formdata, setFormData] = useState({ ...form });
  const [fieldsError, setFieldsError] = useState({});
  const UserInfo = JSON.parse(secureLocalStorage.getItem('user'));
  const dispatch = useDispatch();
  const [serviceGroups, setServiceGroups] = useState([]);

  useEffect(() => {
    InvoiceAPI.operatedAirPorts(UserInfo.branch.code).then((res) => {
      if (res.error) toast.error(res.error);
      var array = [];
      res.data.map((item) => {
        const list = {
          label: item?.iataCode,
          id: item?.id,
          description: item?.iataCode,
        };
        array.push(list);
      });
      setAirPortData(array);
    });
    /*InvoiceAPI.serviceGroups().then(res=>{
            if (res.error) toast.error(res.error)
            var array = []
            res.data.map(item => {
                const list = { label: item?.name, id: item?.id, description: item?.description }
                array.push(list)
            })
            setServiceGroups(array)
        });*/
  }, []);
  useEffect(() => {
    if (getAction() == 1 || getAction() == 2) {
      let itemdata = props.item;
      setForm([
        {
          name: 'airport',
          value: getItemById(airPortData, itemdata.airportId),
        },
        {
          name: 'serviceType',
          value: getItemById(constants.ServiceType, itemdata.serviceType),
        },
        {
          name: 'customerCategory',
          value: getItemById(
            constants.CustomerCategory,
            itemdata.customerCategory
          ),
        },
        { name: 'id', value: itemdata.id },
        { name: 'parameter', value: itemdata.parameter },
        { name: 'formula', value: itemdata.formula },
        { name: 'remarks', value: itemdata.remarks },
      ]);
    }
  }, [airPortData]);

  useEffect(() => {
    resetToDefault();
  }, [props.resetForm]);

  const getAction = () => {
    let ret = null;
    action.map((e, i) => {
      if (e == props.action) {
        ret = i;
      }
    });
    return ret;
  };

  const getItemById = (val, id) => {
    let item = null;
    val.map((e, i) => {
      if (e.id == id) item = e;
    });
    return item;
  };

  const setForm = (payload) => {
    let formvalues = JSON.parse(JSON.stringify(formdata));
    if (Array.isArray(payload)) {
      payload.map((pl, i) => {
        if (
          formvalues[pl.name] &&
          formvalues[pl.name].hasOwnProperty('select')
        ) {
          formvalues[pl.name].select = pl.value;
        } else {
          formvalues[pl.name] = pl.value;
        }
      });
    } else {
      if (
        formvalues[payload.name] &&
        formvalues[payload.name].hasOwnProperty('select')
      ) {
        formvalues[payload.name].select = payload.value;
      } else {
        formvalues[payload.name] = payload.value;
      }
    }
    if (getAction() == 3) props.complete(formvalues);
    setFormData(formvalues);
  };

  const onResetForm = () => {
    setFormData({ ...form });
    props.complete({});
  };
  const resetToDefault = () => {
    setFormData({ ...form });
  };

  const onSubmit = () => {
    dispatch(setGlobalLoading({ open: false, info: '' }));
    let formvalues = JSON.parse(JSON.stringify(formdata));
    let errors = {};
    setFieldsError(errors);
    let postparams = {};
    let validcheck = [
      'parameter',
      'airport',
      'serviceType',
      'formula',
      'customerCategory',
    ];
    let errmsg = null;
    Object.keys(formdata).map((e, i) => {
      let validate = validcheck.find((elm) => elm == e);
      switch (e) {
        case 'airport':
          if (formdata[e].select?.id !== undefined) {
            postparams['airportId'] = formdata[e].select?.id;
          } else {
            if (validate) errors[e] = { error: true };
          }
          break;
        case 'customerCategory':
        case 'serviceType':
          if (formdata[e].select?.id !== undefined) {
            postparams[e] = formdata[e].select?.id;
          } else {
            if (validate) errors[e] = { error: true };
          }
          break;
        case 'id':
          if (getAction() != 0 && getAction() != 2)
            postparams['id'] = formdata[e];
          break;
        default:
          if (
            typeof formdata[e] === 'string' &&
            String(formdata[e]).trim().length &&
            formdata[e] !== null
          ) {
            postparams[e] = formdata[e];
          } else {
            if (validate) errors[e] = { error: true };
          }
          break;
      }
    });
    setFieldsError(errors);
    if (Object.keys(errors).length) {
      errmsg = translate(
        'Fields marked in red are required. Please check the form!'
      );
      toast.error(errmsg);
      return;
    } else {
      dispatch(
        setGlobalLoading({
          open: false,
          info: t(`components.GlobalSpinner.Processing`) + '...',
        })
      );
      ConcessionFeeAPI.saveAcfaoDefinitions(postparams).then((res) => {
        dispatch(setGlobalLoading({ open: false, info: '' }));
        if (res?.statusCode == 500 || res?.error) {
          toast.error(
            res?.data?.message ||
              res?.message ||
              translate('An error occured!') + ':' + res?.error
          );
          return;
        }
        if (!res?.data?.success) {
          let errorsmessage = '';
          res?.data?.errors?.map((e1, i1) => {
            errorsmessage += e1.message + '\n';
          });
          toast.error(
            translate('Definition can not be saved!') + '\n' + errorsmessage
          );
          return;
        }
        toast.success(translate('Definition saved successfully!'));
        setFormData({ ...form });
        if (getAction() == 1 || getAction() == 2) {
          closeButton();
        }
        props.complete();
      });
    }
  };

  const closeButton = () => {
    dispatch(setACFAODefinitionsToggleEditKey(null));
    dispatch(setACFAODefinitionsToggleDuplicateKey(null));
  };

  const checkSpecialChar = (event) => {
    if (
      !(event.code == 'Backspace') &&
      !/[0-9\(\)\.\,\s\+\-\/\*]/.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  const popperStyle = {
    popper: {
      keepMounted: true,
      style: {
        '& .MuiAutocomplete-listbox li': {
          fontSize: '0.8rem !important',
        },
      },
    },
  };
  const textfieldvariant = 'standard';
  const texfieldStyle = {
    '& .MuiInput-root': { fontSize: '0.8rem' },
    '& .MuiInputLabel-root': { fontSize: '0.8rem' },
  };
  const rowstyling = getAction() == 3 && {
    '& .MuiTableCell-root': { background: 'none' },
  };

  return (
    <>
      {getAction() == 3 && (
        <TableRow>
          <TableCell colspan={'100%'}>
            {t(`ConcessionFee.index.Search In Table`)}
          </TableCell>
        </TableRow>
      )}
      <TableRow sx={{ background: '#EFEFEF', ...rowstyling }}>
        <TableCell>
          <TextField
            size="small"
            fullWidth
            name="parameter"
            value={formdata.parameter ? formdata.parameter : ''}
            label={translate('Definition Name')}
            onChange={(e) => {
              setForm({ name: 'parameter', value: e.target.value });
            }}
            variant={textfieldvariant}
            sx={texfieldStyle}
            error={fieldsError?.parameter ? true : false}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            disablePortal
            options={constants.ServiceType}
            size={themeSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate('Service Type')}
                variant={textfieldvariant}
                sx={texfieldStyle}
                error={fieldsError?.serviceType ? true : false}
              />
            )}
            onChange={(e, newValue) => {
              setForm({ name: 'serviceType', value: newValue });
            }}
            componentsProps={popperStyle}
            value={formdata.serviceType.select}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            disablePortal
            options={airPortData}
            size={themeSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate('Airport')}
                variant={textfieldvariant}
                sx={texfieldStyle}
                error={fieldsError?.airport ? true : false}
              />
            )}
            onChange={(e, newValue) => {
              setForm({ name: 'airport', value: newValue });
            }}
            componentsProps={popperStyle}
            value={formdata.airport.select}
          />
        </TableCell>
        <TableCell>
          <Autocomplete
            disablePortal
            options={constants.CustomerCategory}
            size={themeSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate('Customer Category')}
                variant={textfieldvariant}
                sx={texfieldStyle}
                error={fieldsError?.customerCategory ? true : false}
              />
            )}
            onChange={(e, newValue) => {
              setForm({ name: 'customerCategory', value: newValue });
            }}
            componentsProps={popperStyle}
            value={formdata.customerCategory.select}
          />
        </TableCell>
        <TableCell>
          <PriceFunctionBox
            label={translate('Formula')}
            size="small"
            fullWidth
            value={formdata?.formula ? formdata?.formula : ''}
            error={fieldsError?.formula ? true : false}
            onKeyDown={(e) => checkSpecialChar(e)}
            onChange={(condition) => {
              setForm({ name: 'formula', value: condition });
            }}
            variant={textfieldvariant}
            sx={texfieldStyle}
            multiline={true}
            required={false}
          />
        </TableCell>
        <TableCell>
          <TextField
            multiline={true}
            size="small"
            fullWidth
            name="remarks"
            value={formdata.remarks ? formdata.remarks : ''}
            label={translate('Remarks')}
            onChange={(e) => {
              setForm({ name: 'remarks', value: e.target.value });
            }}
            variant={textfieldvariant}
            sx={texfieldStyle}
          />
        </TableCell>
        <TableCell>
          {getAction() != 3 && (
            <IconButton onClick={() => onSubmit()}>
              <Iconify icon="eva:save-fill" />
            </IconButton>
          )}
          {(getAction() == 1 || getAction() == 2) && (
            <IconButton onClick={() => closeButton()}>
              <Iconify icon="gridicons:cross-circle" />
            </IconButton>
          )}
          {getAction() == 3 && (
            <>
              <IconButton
                onClick={() => {
                  onResetForm();
                }}
                title={t(`ConcessionFee.index.Reset Form`)}
              >
                <Iconify icon="eva:refresh-outline" />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
ACFAODefinitionForm.propTypes = {
  item: PropTypes.any,
  action: PropTypes.any,
  complete: PropTypes.func,
  resetForm: PropTypes.bool,
};
