// export const accordionOn = 'accordionOn';
export const settings = 'settings';
export const CAstate = 'CAstate';
export const airlineGroupList = 'airlineGroupList';
export const portfolioManagerList = 'portfolioManagerList';
export const portfolioManagerInuseList = 'portfolioManagerInuseList';
export const agreementTypeList = 'agreementTypeList';
export const companyList = 'companyList';
export const customerList = 'customerList';
export const customerListRecentSearches = 'customerListRecentSearches';
export const customerListSearchInfo = 'customerListSearchInfo';
export const autoRenewalSearchInfo = 'autoRenewalSearchInfo';
export const currencyList = 'CurrencyList';
export const financialInstutationList = 'financialInstutationList';
export const paymentTypeList = 'paymentTypeList';
export const packageDefinitionList = 'packageDefinitionList';
export const standardListRecentSearches = 'standardListRecentSearches';
export const standardListSearchInfo = 'standardListSearchInfo';
export const SAlistDataGrid = 'SAlistDataGrid';
export const serviceList = 'serviceList';
export const serviceRoyaltyList = 'serviceRoyaltyList';
export const royaltyListRecentSearches = 'royaltyListRecentSearches';
export const royaltyListSearchInfo = 'royaltyListSearchInfo';
export const RAlistDataGrid = 'RAlistDataGrid';
export const coeficientTypeList = 'coeficientTypeList';
export const unitList = 'unitList';
export const qualityKpiListRecentSearches = "qualityKpiListRecentSearches";
export const qualityKpiListSearchInfo = "qualityKpiListSearchInfo";
export const annexTypeList = 'annexTypeList';
