import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accordionView: 'on',
  Type: true,
  Services: false,
  Note: false,
  Prices: false,
  Attachments: false,
  Step: '1',
  FilterForm: {
    Company: {
      data: [],
      select: null,
    },
    Airport: {
      data: [],
      select: null,
    },
    Customer: {
      data: [],
      select: null,
    },
    FormType: {
      select: [],
    },
    No: '',
  },
};

export const ServiceFormNavigationSlice = createSlice({
  name: 'ServiceFormNavigation',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.Step = action.payload;
    },
    setAccordionViewOn: (state) => {
      state.accordionView = 'on';
    },
    setAccordionViewOff: (state) => {
      state.accordionView = 'off';
    },
    setStageName: (state, action) => {
      state.Type = true;
      state.Services = false;
      state.Note = false;
      state.Prices = false;
      state.Attachments = false;
    },
    switchAccordionExpandedState: (state, action) => {
      switch (action.payload?.index) {
        case 0:
          state.Type = action.payload?.value;
          break;
        case 1:
          state.Services = action.payload?.value;
          break;
        case 2:
          state.Note = action.payload?.value;
          break;
        case 3:
          state.Prices = action.payload?.value;
          break;
        case 4:
          state.Attachments = action.payload?.value;
          break;
        default:
      }
    },

    setAirport: (state, action) => {
      state.FilterForm.Airport.data = action.payload;
    },

    setAirportSelect: (state, action) => {
      state.FilterForm.Airport.select = action.payload;
    },
    setCustomer: (state, action) => {
      state.FilterForm.Customer.data = action.payload;
    },
    setCustomerSelect: (state, action) => {
      state.FilterForm.Customer.select = action.payload;
    },
    setCompany: (state, action) => {
      state.FilterForm.Company.data = action.payload;
    },
    setCompanySelect: (state, action) => {
      state.FilterForm.Company.select = action.payload;
    },
    resetFilterForm: (state, action) => {
      state.FilterForm = initialState.FilterForm;
    },
    setFormType: (state, action) => {
      state.FilterForm.FormType.select = action.payload;
    },
    setServiceFormNo: (state, action) => {
      state.FilterForm.No = action.payload;
    },
  },
});

export const {
  setAccordionViewOn,
  setAccordionViewOff,
  setStageName,
  switchAccordionExpandedState,
  setStep,
  setAirport,
  setAirportSelect,
  setCustomer,
  setCustomerSelect,
  setCompanySelect,
  setCompany,
  resetFilterForm,
  setFormType,
  setServiceFormNo,
} = ServiceFormNavigationSlice.actions;
export default ServiceFormNavigationSlice.reducer;
