import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import { stateNames } from '../../constants/stateNames';
import { AgreementAPI, cachedApiCall, CoreAPI } from '../../services';
import secureLocalStorage from 'react-secure-storage';
import { db } from '../../indexedDB/db';
import local from '../../utils/localStorageFunc';
import { localStorageNames } from '../../constants';

const initialState = {
  contextVariables: null,
  readyState: stateNames.empty,
  errorText: '',
  serviceGroups: null,
  airlineGroups: null,
  airports: null,
  ACModels: null,
  airlines: null,
  nationalities: null,
  representative: null,
  services: null,
  registrationCode: null,
  flightFunctionList: null,
  suggestionList: null,
};

const L = (value) => {
  let _t = `queryBuilder.index.${value}`;
  return i18n.t(_t);
};

function selectListByVariableName(state, name) {
  switch (name) {
    case 'Nationality':
    case 'FromAirportCountry':
    case 'ToAirportCountry':
    case 'RegistrationNationality':
    case 'NextAirportCountry':
      return state.nationalities;
    case 'FromAirport':
    case 'ToAirport':
    case 'Airport':
    case 'AirportIataCode':
      return state.airports;
    case 'RepresentativeCode':
      return state.representative;
    case 'ACModel':
    case 'ACModelICAO':
    case 'ACModelIcao':
      return state.ACModels;
    case 'Airline':
    case 'AirlineCode':
      return state.airlines;
    case 'AirlineGroup':
    case 'AirlineGroupCode':
      return state.airlineGroups;
    case 'RegistrationCode':
      return state.registrationCode;
    case 'Service':
      return state.services;
    case 'ServiceGroup':
      return state.serviceGroups;
    default:
      return [{ name: 'No Data', label: 'No Data' }];
  }
}

const parseContextVariablesAndFillLists = (state) => {
  state.flightFunctionList = [];
  state.suggestionList = [];
  state.contextVariables.forEach((element) => {
    if (element.condition) {
      switch (element.dataType) {
        case 0: //Select
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'select',
            operators: [
              { name: '=', label: L('equals') },
              { name: '!=', label: L('not equals') },
              { name: 'in', label: 'is in list' },
              { name: 'notin', label: 'is not in list' },
            ],
            values: selectListByVariableName(state, element.variableName),
          });
          break;
        case 1: //Always
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'checkbox',
            operators: [{ name: '=', label: 'is' }],
            defaultValue: true,
          });
          break;
        case 2: // Date Picker
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'date',
            operators: [
              { name: '=', label: L('equals') },
              { name: '!=', label: L('not equals') },
              { name: '>', label: L('greater than') },
              { name: '<', label: L('less than') },
              { name: '<=', label: L('less than or equal to') },
              { name: '>=', label: L('greater than or equal to') },
            ],
          });
          break;
        case 3: // Number (Double , integer)
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'number',
            operators: [
              { name: '=', label: L('equals') },
              { name: '!=', label: L('not equals') },
              { name: '>', label: L('greater than') },
              { name: '<', label: L('less than') },
              { name: '<=', label: L('less than or equal to') },
              { name: '>=', label: L('greater than or equal to') },
            ],
          });
          break;
        case 4: // Boolean
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'checkbox',
            operators: [{ name: '=', label: 'is' }],
            defaultValue: true,
          });
          break;
        case 5: // String
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'text',
            operators: [
              { name: '=', label: L('equals') },
              { name: '!=', label: L('not equals') },
            ],
          });
          break;
        case 6: //Functions
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'text',
            operators: [
              { name: '=', label: L('equals') },
              { name: '!=', label: L('not equals') },
            ],
          });
          break;
        case 7: // Boolean Expression
          state.flightFunctionList.push({
            name: element.variableName,
            label: element.label ?? element.variableName,
            valueEditorType: 'boolean',
            operators: [
              { name: '=', label: L('is') },
              { name: '!=', label: L('is not') },
            ],
          });
          break;
        default:
      }
    }

    if (element.priceFunction && element.dataType !== 0) {
      // 0 is select type
      state.suggestionList.push(element.variableName);
    }
  });
  state.suggestionList = _.uniq(state.suggestionList);
};

const { conditionLoaders } = db;

export async function loadConditionData(state, dispatch) {
  if (
    state.condition.readyState === stateNames.ready ||
    state.condition.readyState === stateNames.loading
  )
    return; // already loaded state
  dispatch(setReadyState(stateNames.loading));
  try {
    // Context Variables
    const contextVariables = await conditionLoaders.get({ name: 'contextVariables' });

    if (contextVariables !== undefined) {
      dispatch(setContextVariables(contextVariables.value));
    } else {
      const { data: dataContextVariable, error: errorContextVariable } = await cachedApiCall({
        apiCall: AgreementAPI.ContextVariable.list,
        localStorageName: null,
      });
      if (errorContextVariable) {
        toast(errorContextVariable);
        dispatch(appendErrorText(errorContextVariable));
      } else {
        const dbData = { name: 'contextVariables', value: dataContextVariable, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setContextVariables(dataContextVariable));
      }
    }

    // SERVICE GROUP
    const serviceGroup = await conditionLoaders.get({ name: 'serviceGroup' });

    if (serviceGroup !== undefined) {
      dispatch(setServiceGroups(serviceGroup.value));
    } else {
      const { data: dataServiceGroup, error: errorServiceGroup } = await cachedApiCall({
        apiCall: CoreAPI.serviceGroups,
        localStorageName: null,
        dataParser: (e) => ({ name: e.name, label: e.name }),
      });

      if (errorServiceGroup) {
        toast(errorServiceGroup);
        dispatch(appendErrorText(errorServiceGroup));
      } else {
        const dbData = { name: 'serviceGroup', value: dataServiceGroup, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setServiceGroups(dataServiceGroup));
      }
    }

    // AIRLINE GROUP
    const airlineGroup = await conditionLoaders.get({ name: 'airlineGroup' });

    if (airlineGroup !== undefined) {
      dispatch(setAirlineGroups(airlineGroup.value));
    } else {
      const { data: dataAirlineGroup, error: errorAirlineGroup } = await cachedApiCall({
        apiCall: CoreAPI.airlineGroups,
        localStorageName: null,
        dataParser: (e) => ({ name: e.name, label: e.name }),
      });

      if (errorAirlineGroup) {
        toast(errorAirlineGroup);
        dispatch(appendErrorText(errorAirlineGroup));
      } else {
        const sortedDataAirlineGroup = _.sortBy(dataAirlineGroup, 'name');

        const dbData = { name: 'airlineGroup', value: sortedDataAirlineGroup, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setAirlineGroups(sortedDataAirlineGroup));
      }
    }

    // AIRPORT
    const userInfo = JSON.parse(secureLocalStorage.getItem('user'));
    const branchCode = userInfo?.branch?.code;
    const airport = await conditionLoaders.get({ name: 'airport' });

    if (airport !== undefined) {
      dispatch(setAirports(airport.value));
    } else {
      const { data: dataAirports, error: errorAirports } = await cachedApiCall({
        apiCall: CoreAPI.operatedAirports,
        apiCallArgs: [branchCode],
        localStorageName: null,
        dataParser: (e) => {
          return { id: e?.id, name: e?.iataCode, label: e?.iataCode + ' - ' + e?.name };
        },
      });

      if (errorAirports) {
        toast(errorAirports);
        dispatch(appendErrorText(errorAirports));
      } else {
        let sortedDataAirports = _.sortBy(dataAirports, 'name');
        sortedDataAirports = _.remove(sortedDataAirports, (e) => e.name);

        const dbData = { name: 'airport', value: sortedDataAirports, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setAirports(sortedDataAirports));
      }
    }

    // ACMODEL
    const acModel = await conditionLoaders.get({ name: 'acModel' });

    if (acModel !== undefined) {
      dispatch(setACModels(acModel.value));
    } else {
      const { data: dataACModel, error: errorACModel } = await cachedApiCall({
        apiCall: CoreAPI.acModels,
        localStorageName: null,
        dataParser: (e) => ({
          id: e.id,
          name: e.customCode,
          label: e.customCode + ' - ' + e.name,
        }),
      });

      if (errorACModel) {
        toast(errorACModel);
        dispatch(appendErrorText(errorACModel));
      } else {
        let sortedList = _.sortBy(dataACModel, 'name');
        let filteredList = _.remove(sortedList, (e) => e.name);

        const dbData = { name: 'acModel', value: filteredList, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setACModels(filteredList));
      }
    }

    // AIRLINE
    const airline = await conditionLoaders.get({ name: 'airline' });

    if (airline !== undefined) {
      dispatch(setAirlines(airline.value));
    } else {
      const { data: dataAirline, error: errorAirline } = await cachedApiCall({
        apiCall: CoreAPI.airlines,
        localStorageName: null,
        dataParser: (e) => ({
          id: e.id,
          name: e.customCode,
          label: e.customCode + ' - ' + e.name,
        }),
      });

      if (errorAirline) {
        toast(errorAirline);
        dispatch(appendErrorText(errorAirline));
      } else {
        let sortedList = _.sortBy(dataAirline, 'name');
        let filteredList = _.remove(sortedList, (e) => e.name);

        const dbData = { name: 'airline', value: filteredList, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setAirlines(filteredList));
      }
    }

    // NATIONALITY
    const nationality = await conditionLoaders.get({ name: 'nationality' });

    if (nationality !== undefined) {
      dispatch(setNationalities(nationality.value));
    } else {
      const { data: dataNationality, error: errorNationality } = await cachedApiCall({
        apiCall: CoreAPI.countries,
        localStorageName: null,
        dataParser: (e) => ({ name: e.code, label: e.code + ' - ' + e.name }),
      });

      if (errorNationality) {
        toast(errorNationality);
        dispatch(appendErrorText(errorNationality));
      } else {
        const dbData = { name: 'nationality', value: dataNationality, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setNationalities(dataNationality));
      }
    }

    // SERVICE
    const service = await conditionLoaders.get({ name: 'service' });

    if (service !== undefined) {
      dispatch(setServices(service.value));
    } else {
      const { data: dataService, error: errorService } = await cachedApiCall({
        apiCall: CoreAPI.services,
        localStorageName: null,
        dataParser: (e) => ({ name: e.code, label: e.code + ' - ' + e.name }),
      });

      if (errorService) {
        toast(errorService);
        dispatch(appendErrorText(errorService));
      } else {
        const dbData = { name: 'service', value: dataService, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setServices(dataService));
      }
    }

    // REPRESENTATIVE
    const representative = await conditionLoaders.get({ name: 'representative' });

    if (representative !== undefined) {
      dispatch(setRepresentative(representative.value));
    } else {
      const { data: dataRepresentative, error: errorRepresentative } = await cachedApiCall({
        apiCall: CoreAPI.representatives,
        localStorageName: null,
        dataParser: (e) => ({
          name: e.customer.code,
          label: e.customer.code + ' - ' + e.customer.name,
        }),
      });

      if (errorRepresentative) {
        toast(errorRepresentative);
        dispatch(appendErrorText(errorRepresentative));
      } else {
        const dbData = { name: 'representative', value: dataRepresentative, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setRepresentative(dataRepresentative));
      }
    }

    // REGISTRATION
    const registration = await conditionLoaders.get({ name: 'registration' });

    if (registration !== undefined) {
      dispatch(setRegistrationCode(registration.value));
    } else {
      const { data: dataRegistration, error: errorRegistration } = await cachedApiCall({
        apiCall: CoreAPI.registrations,
        localStorageName: null,
        dataParser: (e) => ({ name: e.code, label: e.code }),
      });

      if (errorRegistration) {
        toast(errorRegistration);
        dispatch(appendErrorText(errorRegistration));
      } else {
        const sortedDataRegistration = _.sortBy(dataRegistration, 'label');

        const dbData = { name: 'registration', value: sortedDataRegistration, date: new Date() };
        conditionLoaders.add(dbData);
        dispatch(setRegistrationCode(sortedDataRegistration));
      }
    }

    dispatch(parseConditionSlice());
  } catch (e) {
    toast(e.message);
    console.log(e);
    dispatch(setReadyState(stateNames.error));
    return;
  }
  dispatch(setReadyState(stateNames.ready));
}

export async function reloadRegistrations(state, dispatch) {

  const customerCode = local.get(localStorageNames.customerListSearchInfo)?.Customer?.code
  const registrationKey = customerCode? `registration_${customerCode}`:'registration'
  const registration = await conditionLoaders.get({ name:registrationKey });

  console.log("registration",registration);

  if (registration !== undefined) {
    dispatch(setRegistrationCode(registration.value));
  } else {
    const { data: dataRegistration, error: errorRegistration } = await cachedApiCall({
      apiCall:  customerCode ? CoreAPI.registrationsByCustomerCode : CoreAPI.registrations,
      localStorageName: null,
    ...(customerCode && {apiCallArgs:[customerCode]}),
      dataParser: (e) => ({ name: e.code, label: e.code }),
    });

    if (errorRegistration) {
      toast(errorRegistration);
      dispatch(appendErrorText(errorRegistration));
    } else {
      
      const sortedDataRegistration = _.sortBy(dataRegistration, 'label');

      const dbData = { name: registrationKey, value: sortedDataRegistration, date: new Date() };
      conditionLoaders.add(dbData);
      dispatch(setRegistrationCode(sortedDataRegistration));
    }

    dispatch(parseConditionSlice());
  }
}

export const conditionSlice = createSlice({
  name: 'condition',
  initialState,
  reducers: {
    parseConditionSlice: (state) => {
      parseContextVariablesAndFillLists(state);
    },
    setReadyState: (state, action) => {
      state.readyState = action.payload;
    },
    appendErrorText: (state, action) => {
      state.errorText += '\n\n' + action.payload;
    },
    setContextVariables: (state, action) => {
      state.contextVariables = action.payload;
    },
    setServiceGroups: (state, action) => {
      state.serviceGroups = action.payload;
    },
    setAirlineGroups: (state, action) => {
      state.airlineGroups = action.payload;
    },
    setAirports: (state, action) => {
      state.airports = action.payload;
    },
    setACModels: (state, action) => {
      state.ACModels = action.payload;
    },
    setAirlines: (state, action) => {
      state.airlines = action.payload;
    },
    setNationalities: (state, action) => {
      state.nationalities = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setRepresentative: (state, action) => {
      state.representative = action.payload;
    },
    setRegistrationCode: (state, action) => {
      state.registrationCode = action.payload;
    },
    setClearConditionSlice: (state) => {
      return initialState
    }
  },
});

export const {
  parseConditionSlice,
  setReadyState,
  appendErrorText,
  setContextVariables,
  setServiceGroups,
  setAirlineGroups,
  setAirports,
  setACModels,
  setAirlines,
  setNationalities,
  setServices,
  setRepresentative,
  setRegistrationCode,
  setClearConditionSlice,
} = conditionSlice.actions;
export default conditionSlice.reducer;
