import request from './request';
import requestServiceForm from './requestServiceForm';

class ServiceFormApi {
  static formType = async () => {
    return await request.get('api/formType');
  };

  static searchOperatedAirportsWithCodeOrName = async (search) => {
    return await request.post(
      'api/operatedairports/search/codeNameRequest',
      search
    );
  };

  static searchAirportsWithCompany = async (params) => {
    return await request.post('api/operatedairports/', params);
  };

  static createOrUpdate = async (body) => {
    return await requestServiceForm.post(
      'api/serviceForm/createOrUpdate/',
      body
    );
  };
}

export { ServiceFormApi };
