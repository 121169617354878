import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocale from '../../../hooks/useLocale';

import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import toast from 'react-hot-toast';
import secureLocalStorage from 'react-secure-storage';
import ConfirmDialog from '../../../components/ConfirmDialog';
import IconButton from '../../../components/IconButton';
import Iconify from '../../../components/Iconify';
import PageContainer from '../../../components/PageContainer';
import { TableHeadCell } from '../../../components/Table/TableHeadCell';
import {
  setACFAODefinitionsOrder,
  setACFAODefinitionsResultset,
  setACFAODefinitionsSearchParams,
  setACFAODefinitionsToggleDuplicateKey,
  setACFAODefinitionsToggleEditKey,
} from '../../../contexts/concessionfee/concessionFeeSlice';
import { setGlobalLoading } from '../../../contexts/mainSlice';
import usePermissions from '../../../hooks/usePermissions';
import { ConcessionFeeAPI, InvoiceAPI } from '../../../services';
import ACFAODefinitionForm from '../ACFAODefinitionForm';

export default function ACFAODefinitionsTable() {
  //const { themeSize } = useSettings();
  const dispatch = useDispatch();

  {
    /* Translate */
  }
  const { t } = useLocale();
  const translate = (value) => {
    let _t = `ConcessionFee.FormulaTable.index.${value}`;
    return t(_t);
  };
  const { hasPermission } = usePermissions({ pageName: 'ACFAODefinitions' });
  const data = useSelector(
    (state) => state.ConcessionFee.ACFAODefinitions.Data
  );
  const serviceType = useSelector((state) => state.ConcessionFee.ServiceType);
  const customerCategory = useSelector(
    (state) => state.ConcessionFee.CustomerCategory
  );
  const toggleEditKey = useSelector(
    (state) => state.ConcessionFee.ACFAODefinitions.toggleEditKey
  );
  const toggleDuplicateKey = useSelector(
    (state) => state.ConcessionFee.ACFAODefinitions.toggleDuplicateKey
  );
  const [airPortData, setAirPortData] = useState([]);
  const UserInfo = JSON.parse(secureLocalStorage.getItem('user'));
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [serviceGroups, setServiceGroups] = useState([]);
  const tableOrder = useSelector(
    (state) => state.ConcessionFee.ACFAODefinitions.tableOrder
  );
  const searchParams = useSelector(
    (state) => state.ConcessionFee.ACFAODefinitions.searchParams
  );
  const [originsort, setOriginSort] = useState([]);
  const [resetSearchForm, setResetSearchForm] = useState(Date.now());

  const sortHandler = (order, orderobj) => {
    dispatch(setACFAODefinitionsOrder(orderobj));
    if (order) {
      setSortData(data, orderobj);
    } else {
      if (searchParams) {
        searchInTable(searchParams);
      } else {
        dispatch(setACFAODefinitionsResultset(originsort));
      }
    }
  };

  const setSortData = (data, tableorder) => {
    let sortingdata = [];
    data.map((item, key) => {
      sortingdata[key] = {};
      Object.keys(item).map((col, ck) => {
        switch (col) {
          case 'serviceType':
            sortingdata[key][col] = getLabelById(serviceType, item.serviceType);
            break;
          case 'airportId':
            sortingdata[key][col] = getLabelById(airPortData, item.airportId);
            break;
          case 'customerCategory':
            sortingdata[key][col] = getLabelById(
              customerCategory,
              item.customerCategory
            );
            break;
          default:
            sortingdata[key][col] = item[col];
            break;
        }
      });
    });
    let orderdata = null;
    Object.keys(tableorder).map((ti, tk) => {
      orderdata = tableorder[ti];
    });

    if (orderdata) {
      sortingdata.sort((n1, n2) => {
        if (typeof n1[orderdata[0]] == 'string') {
          return (
            n1[orderdata[0]].localeCompare(n2[orderdata[0]]) *
            (orderdata[1] == 'desc' ? -1 : 1)
          );
        } else {
          switch (orderdata[1]) {
            case 'asc':
              return n1[orderdata[0]] > n2[orderdata[0]] ? 1 : -1;
              break;
            case 'desc':
              return n1[orderdata[0]] > n2[orderdata[0]] ? -1 : 1;
              break;
          }
        }
      });
    }

    let sorteddata = [];
    sortingdata.map((item, key) => {
      data.map((d, k) => {
        if (d.id == item.id) sorteddata.push(d);
      });
    });
    dispatch(setACFAODefinitionsResultset(sorteddata));
  };

  const searchInTable = (params) => {
    dispatch(setACFAODefinitionsSearchParams(params));
    let searcheddata = originsort;
    Object.keys(params).map((e, i) => {
      searcheddata = searchData(e, params[e], searcheddata);
    });
    setSortData(searcheddata, tableOrder);
    dispatch(setACFAODefinitionsResultset(searcheddata));
  };

  const searchData = (colname, colvalue, searcheddata) => {
    if (
      colvalue == null ||
      ((typeof colvalue?.select == 'object' ||
        typeof colvalue?.select == 'array') &&
        !colvalue?.select?.id)
    ) {
      return searcheddata;
    }
    let newdata = searcheddata.filter((item) => {
      if (
        (typeof colvalue?.select == 'object' ||
          typeof colvalue?.select == 'array') &&
        colvalue?.select?.id
      ) {
        if (item[colname]?.id == colvalue?.select?.id) {
          return true;
        }
        if (item[colname] == colvalue?.select?.id) {
          return true;
        }
        switch (colname) {
          case 'airport':
            if (item['airportId'] == colvalue?.select?.id) {
              return true;
            }
            break;
        }
      } else {
        let expression = `.*${colvalue}.*`;
        let re = new RegExp(expression, 'gi');
        if (re.test(item[colname])) {
          return true;
        }
      }
      return false;
    });
    return newdata;
  };

  const searchACFAODefinitions = async (order) => {
    dispatch(
      setGlobalLoading({
        open: true,
        info: t(`components.GlobalSpinner.Processing`) + '...',
      })
    );
    ConcessionFeeAPI.acfaoDefinitions().then((res) => {
      if (res.error) {
        toast.error(res.error);
        return;
      }
      setOriginSort(res.data);
      if (order) {
        setSortData(res.data, order);
      } else {
        setSortData(res.data, tableOrder);
      }
      dispatch(setGlobalLoading({ open: false, info: '' }));
    });
  };

  useEffect(() => {
    InvoiceAPI.operatedAirPorts(UserInfo.branch.code).then((res) => {
      if (res.error) toast.error(res.error);
      var array = [];
      res.data.map((item) => {
        const list = {
          label: item?.iataCode,
          id: item?.id,
          description: item?.iataCode,
        };
        array.push(list);
      });
      setAirPortData(array);
    });
    searchACFAODefinitions();
  }, []);

  useEffect(() => {
    searchInTable(searchParams);
  }, originsort);

  const getItemById = (val, id) => {
    let item = null;
    val.map((e, i) => {
      if (e.id == id) item = e;
    });
    return item;
  };

  const getLabelById = (val, id) => {
    let typename = '';
    val.map((e, i) => {
      if (e.id == id) typename = e.label;
    });
    return typename;
  };

  const editItem = (key) => {
    dispatch(setACFAODefinitionsToggleEditKey(key));
  };
  const duplicateItem = (key) => {
    dispatch(setACFAODefinitionsToggleDuplicateKey(key));
  };
  const deleteItem = () => {
    setConfirmCancelVisible(false);
    ConcessionFeeAPI.deleteAcfaoDefinition(deleteId).then((res) => {
      if (res.error) {
        toast.error(res.error);
        return;
      }
      searchACFAODefinitions();
    });
  };

  const renderCancelConfirmDialog = useMemo(() => {
    return (
      confirmCancelVisible && (
        <ConfirmDialog
          visible={confirmCancelVisible}
          setVisible={setConfirmCancelVisible}
          onClick={deleteItem}
          title={t('components.ConfirmDialog.index.Confirm Transaction')}
        >
          <Typography>
            {t(
              'components.ConfirmDialog.index.Are you sure to delete ACFAO Definition data?'
            )}
          </Typography>
        </ConfirmDialog>
      )
    );
  }, [confirmCancelVisible, deleteItem, t]);

  const headStyle = {
    '& > th, td': {
      '.MuiButtonBase-root.MuiTableSortLabel-root.Mui-active': {
        color: '#ffffff',
      },
      '.MuiButtonBase-root.MuiTableSortLabel-root': {
        '&:hover, &:focus': { color: 'rgba(255, 255, 255, 0.8)' },
      },
    },
  };

  return (
    <PageContainer>
      <TableContainer component={Paper} style={{ overflow: 'visible' }}>
        <Table>
          <TableHead>
            <ACFAODefinitionForm
              action="Search"
              complete={searchInTable}
              resetForm={resetSearchForm}
            ></ACFAODefinitionForm>
          </TableHead>
          <TableHead>
            <TableRow key={'head'} sx={headStyle}>
              <TableHeadCell
                tableOrder={tableOrder}
                columnKey={'parameter'}
                onSortAction={(o, ob) => {
                  sortHandler(o, ob);
                }}
                style={{ width: '15rem' }}
              >
                {translate('Formula Name')}
              </TableHeadCell>
              <TableHeadCell
                tableOrder={tableOrder}
                columnKey={'serviceType'}
                onSortAction={(o, ob) => {
                  sortHandler(o, ob);
                }}
                style={{ width: '10rem' }}
              >
                {translate('Service Type')}
              </TableHeadCell>
              <TableHeadCell
                tableOrder={tableOrder}
                columnKey={'airportId'}
                onSortAction={(o, ob) => {
                  sortHandler(o, ob);
                }}
                style={{ width: '10rem' }}
              >
                {translate('Airport')}
              </TableHeadCell>
              <TableHeadCell
                tableOrder={tableOrder}
                columnKey={'customerCategory'}
                onSortAction={(o, ob) => {
                  sortHandler(o, ob);
                }}
                style={{ width: '10rem' }}
              >
                {translate('Customer Category')}
              </TableHeadCell>
              <TableHeadCell
                tableOrder={tableOrder}
                columnKey={'formula'}
                onSortAction={(o, ob) => {
                  sortHandler(o, ob);
                }}
              >
                {translate('Formula')}
              </TableHeadCell>
              <TableHeadCell
                tableOrder={tableOrder}
                columnKey={'remarks'}
                onSortAction={(o, ob) => {
                  sortHandler(o, ob);
                }}
                style={{ width: '15rem' }}
              >
                {translate('Remarks')}
              </TableHeadCell>
              <TableCell style={{ width: '7.5rem' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasPermission('Add') ? (
              <ACFAODefinitionForm
                action="Add"
                complete={() => {
                  dispatch(setACFAODefinitionsOrder({ id: ['id', 'desc'] }));
                  searchACFAODefinitions({ id: ['id', 'desc'] });
                }}
              />
            ) : (
              <></>
            )}
            {data && data.length == 0 ? (
              <TableCell colspan={'100%'}>
                <Alert severity="info">
                  {t(`ConcessionFee.index.There were no results.`)}
                </Alert>
              </TableCell>
            ) : (
              <>
                {data &&
                  data.map((item, key) => (
                    <>
                      {toggleEditKey !== key && (
                        <TableRow key={key}>
                          <TableCell>{item.parameter}</TableCell>
                          <TableCell>
                            {getLabelById(serviceType, item.serviceType)}
                          </TableCell>
                          <TableCell>
                            {getLabelById(airPortData, item.airportId)}
                          </TableCell>
                          <TableCell>
                            {getLabelById(
                              customerCategory,
                              item?.customerCategory
                            )}
                          </TableCell>
                          <TableCell>{item.formula}</TableCell>
                          <TableCell>
                            <div style={{ width: '15rem' }}>{item.remarks}</div>
                          </TableCell>
                          <TableCell>
                            {(!toggleDuplicateKey ||
                              toggleDuplicateKey != key) && (
                              <div style={{ width: '7.5rem' }}>
                                {hasPermission('Update') && (
                                  <IconButton
                                    onClick={() => {
                                      editItem(key);
                                    }}
                                    title={t('ConcessionFee.index.Edit')}
                                  >
                                    <Iconify icon="eva:edit-fill" />
                                  </IconButton>
                                )}
                                {hasPermission('Update') && (
                                  <IconButton
                                    onClick={() => {
                                      duplicateItem(key);
                                    }}
                                    title={t('ConcessionFee.index.Duplicate')}
                                  >
                                    <Iconify icon="eva:copy-fill" />
                                  </IconButton>
                                )}
                                {hasPermission('Delete') && (
                                  <IconButton
                                    onClick={() => {
                                      setDeleteId(item.id);
                                      setConfirmCancelVisible(true);
                                    }}
                                    title={t('ConcessionFee.index.Delete')}
                                  >
                                    <Iconify icon="eva:trash-2-outline" />
                                  </IconButton>
                                )}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {toggleEditKey === key && (
                        <ACFAODefinitionForm
                          action="Edit"
                          item={item}
                          complete={() => {
                            searchACFAODefinitions();
                          }}
                        ></ACFAODefinitionForm>
                      )}
                      {toggleDuplicateKey == key && (
                        <ACFAODefinitionForm
                          action="Duplicate"
                          item={item}
                          complete={() => {
                            searchACFAODefinitions();
                          }}
                        ></ACFAODefinitionForm>
                      )}
                    </>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {renderCancelConfirmDialog}
    </PageContainer>
  );
}
